import type { InputBaseComponentProps } from "@mui/material";
import { forwardRef } from "react";
import NumberFormat from "react-number-format";

type NumberFormatInputProps = Omit<InputBaseComponentProps, "onChange"> & {
  decimalPlaces?: number;
};

export const NumberFormatInput = forwardRef<HTMLInputElement, NumberFormatInputProps>(
  (props, reference) => {
    const { onChange, decimalPlaces, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={reference}
        onValueChange={(values) => {
          onChange(values.formattedValue);
        }}
        decimalScale={decimalPlaces}
        fixedDecimalScale={true}
        isNumericString
      />
    );
  },
);

NumberFormatInput.displayName = "NumberFormatInput";
