import { FormControl, FormHelperText, FormLabel, OutlinedInput, Typography } from "@mui/material";
import type { ComponentProps } from "react";
import { forwardRef, useId } from "react";

import { currencyInfo as getCurrencyInfo, parsePrice, toSmallestUnit } from "~/modules/currency";
import type { Currency } from "~/types";

import { FormItemContext, useFormItem } from "./form-item-context";
import { NumberFormatInput } from "./number-format-input";

const Root = forwardRef<HTMLDivElement, ComponentProps<typeof FormControl>>(function Root(
  props,
  reference,
) {
  const id = useId();
  return (
    <FormItemContext.Provider value={{ id }}>
      <FormControl ref={reference} {...props} />
    </FormItemContext.Provider>
  );
});

const Label = forwardRef<HTMLLabelElement, ComponentProps<typeof FormLabel>>(function Label(
  props,
  reference,
) {
  const { id } = useFormItem();
  return <FormLabel ref={reference} htmlFor={id} {...props} />;
});

const Input = forwardRef<HTMLInputElement, ComponentProps<typeof OutlinedInput>>(function Input(
  props,
  reference,
) {
  const { id } = useFormItem();
  return <OutlinedInput inputRef={reference} id={id} autoComplete="off" {...props} />;
});

type InputMoneyProps = Omit<ComponentProps<typeof OutlinedInput>, "value" | "onChange"> & {
  currency: Currency;
  value?: number;
  onChange?: (value?: number) => void;
};
const InputMoney = forwardRef<HTMLInputElement, InputMoneyProps>(function InputMoney(
  props,
  reference,
) {
  const { currency, value, onChange, ...restProps } = props;
  const { id } = useFormItem();

  const currencyInfo = getCurrencyInfo(currency);

  return (
    <OutlinedInput
      inputRef={reference}
      id={id}
      startAdornment={
        <Typography variant="b5" component="span" color="gray.5" sx={{ mr: 1 }}>
          {currencyInfo.symbol}
        </Typography>
      }
      inputProps={{
        decimalPlaces: currencyInfo.decimalPlaces,
      }}
      inputComponent={NumberFormatInput}
      value={value ? parsePrice(value, currency) : ""}
      onChange={(value: any) => {
        // when the value is cleared, reset the value to 0 instead of undefined
        // since form libraries may interpret nullish value as a non-update
        // and revert the value to the original default value
        // for example, if default value is 1000, after backspaccing, the value will become
        // 100, 10, 1, then back to 1000
        if (!value) return onChange?.(0);
        let amount = Number.parseInt(toSmallestUnit(String(value), currency));
        onChange?.(Number.isNaN(amount) ? undefined : amount);
      }}
      autoComplete="off"
      {...restProps}
    />
  );
});

export const TextField = {
  Root,
  Label,
  Input,
  InputMoney,
  Helper: FormHelperText,
};
