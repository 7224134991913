import { createContext, useContext } from "react";

type FormItemContextValue = {
  id: string;
};

export const FormItemContext = createContext<FormItemContextValue | string>(
  "useFormItem must be used within a <FormItemProvider />",
);

export function useFormItem() {
  const context = useContext(FormItemContext);

  if (typeof context === "string") {
    throw new TypeError(context);
  }

  return context;
}
